// src/components/info/NoAccess.js
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { setCredentials } from 'src/redux/slices/authSlice';
import { orgService } from 'src/services/orgService';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

const NoAccess = ({ inactive }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  // copiedCommand tracks which copy button was last clicked.
  const [copiedCommand, setCopiedCommand] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Get token and clientArn from Redux (or fallback)
  const { token } = useSelector((state) => state.user);
  const clientArn = useSelector((state) => state.awsAccount?.clientArn) || "No ARN found";

  // The AWS IAM role creation script (same as in AddAccount.js)
  const script = `import boto3
import json

# Create IAM client
iam = boto3.client('iam')

# Define the trust policy document
trust_policy_document = {
    "Version": "2012-10-17",
    "Statement": [
        {
            "Effect": "Allow",
            "Principal": {
                "AWS": "arn:aws:iam::145600289260:root"
            },
            "Action": "sts:AssumeRole"
        }
    ]
}

# Convert the trust policy document to a JSON string
trust_policy_document_str = json.dumps(trust_policy_document)

# Create the IAM role with the trust policy document
role_response = iam.create_role(
    RoleName='AWSMO_TrustedAdminAccessRole',
    AssumeRolePolicyDocument=trust_policy_document_str
)

# Attach the AWS managed AdministratorAccess policy to the role
iam.attach_role_policy(
    RoleName='AWSMO_TrustedAdminAccessRole',
    PolicyArn='arn:aws:iam::aws:policy/AdministratorAccess'
)

# Print the ARN of the IAM role
role_arn = role_response['Role']['Arn']
print("IAM role created with ARN:", role_arn)
`;

  /**
   * Copy text (command or script) to clipboard and show a toast.
   * After 2 seconds, the copied button text is reset to allow copying again.
   */
  const copyToClipboard = (text, command, event) => {
    event.stopPropagation();
    // Allow copy if either nothing was copied or a different command was copied previously.
    if (copiedCommand !== command) {
      navigator.clipboard.writeText(text)
        .then(() => {
          setCopiedCommand(command);
          const toastId = toast.success('Copied to Clipboard');
          setTimeout(() => {
            toast.dismiss(toastId);
            setCopiedCommand(''); // Reset the copied command text after 2 seconds
          }, 2000);
        })
        .catch((err) => {
          toast.error('Failed to copy');
          console.error(err);
        });
    }
  };

  /**
   * Re-check permissions: calls orgService to see if permission is now granted.
   * If valid, updates Redux and redirects to the dashboard;
   * otherwise, shows an error message.
   */
  const handleRecheck = async () => {
    setLoading(true);
    setMessage('');
    try {
      const orgData = await orgService.getOrganization(token);
      if (orgData && orgData.length > 0) {
        const awsAccountData = orgData[0]?.aws_accounts;
        const permissionStatus = awsAccountData[0]?.permission_status;
        const isProcessed = awsAccountData[0]?.stage === "STAGE_AG_CREATED";
        const newAccessStatus = permissionStatus && isProcessed;

        // Update Redux flags
        dispatch(setCredentials({ 
          user: orgData.user, 
          token, 
          processedStatus: newAccessStatus,
          permissionStatus 
        }));

        if (permissionStatus) {
          toast.success("Permissions validated! Welcome back.");
          navigate("/");
        } else {
          setMessage("AWS account permissions are still not valid. Please ensure you've run the script properly.");
          toast.error("Permissions still not valid.");
        }
      } else {
        setMessage("Unable to retrieve your account details. Please try again later.");
        toast.error("Failed to retrieve account details.");
      }
    } catch (error) {
      console.error("Error re-checking permissions:", error);
      setMessage("An error occurred while checking your AWS account status. Please try again.");
      toast.error("Error checking AWS account status.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="margin-top-dash font-open" style={{ minHeight: "100vh", backgroundColor: "#f8f9fa" }}>
      <div className="rightContainer">
        <div className="p-3 p-lg-4">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="text-black f18 font-open font-weight-bold">
              AWS Account Permissions Required
            </div>
          </div>
          <div className="row">
            {/* Left Column: Restructured Content */}
            <div className="col-md-12 col-lg-6">
              <div className="bg-white p-3 p-lg-4 desh-height">
                {/* Explain why the user landed here */}
                <h4 className="text-center text-black mb-3 font-weight-bold">
                  AWS Permissions Issue
                </h4>
                <p className="mb-3">
                  Your AWS account did not pass one or more permission checks. Please ensure it has EC2, CloudWatch Logs, and Billing access.
                </p>
                {/* Display Client ARN in bold */}
                <div className="mb-4">
                  <h6 className="mb-2 font-weight-bold">Your Client ARN</h6>
                  <div style={{
                    backgroundColor: "#f8f9fa",
                    padding: "10px",
                    borderRadius: "5px",
                    fontWeight: "bold",
                    wordBreak: "break-word"
                  }}>
                    {clientArn}
                  </div>
                </div>
                {/* Instructions to reconfigure */}
                <div className="mb-4">
                  <h6 className="mb-2 font-weight-bold">Instructions to Reconfigure Your AWS Account</h6>
                  <p>
                    Follow these steps carefully to re-establish a secure connection between your AWS account and our system:
                  </p>
                </div>
                <div className="mb-4">
                  <h6 className="mb-2 font-weight-bold">Step 1: Obtain the Script</h6>
                  <p>
                    You have two options to get the script:
                  </p>
                  <p>
                    <strong>Option A:</strong> Click the "Copy Script" button on the right and manually create a new file named <code>add_arn.py</code> in your AWS CloudShell, then paste the script.
                  </p>
                  <p>
                    <strong>Option B:</strong> Directly download the script using the following command in your AWS CloudShell:
                  </p>
                  <div className="code-box align-center m-2 p-2 mb-2"
                    style={{
                      position: 'relative',
                      width: '100%',
                      backgroundColor: '#f8f9fa',
                      border: '1px solid #dee2e6',
                      borderRadius: '5px',
                      padding: '0.5rem',
                      overflow: 'auto',
                      fontSize: '16px',
                      fontFamily: 'monospace'
                    }}>
                    wget https://awsmo.ai/onboarding-script -O add_arn.py
                    <button onClick={(e) => copyToClipboard('wget https://awsmo.ai/onboarding-script -O add_arn.py', 'wget', e)}
                      style={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        padding: '2px 8px',
                        fontSize: '12px'
                      }}
                      className="btn">
                      <ContentPasteIcon />
                    </button>
                  </div>
                </div>
                <div className="mb-4">
                  <h6 className="mb-2 font-weight-bold">Step 2: Execute the Script</h6>
                  <p>
                    After obtaining the script, execute it by typing the following command in your AWS CloudShell:
                  </p>
                  <div className="code-box align-center m-2 p-2 mb-2"
                    style={{
                      position: 'relative',
                      width: '100%',
                      backgroundColor: '#f8f9fa',
                      border: '1px solid #dee2e6',
                      borderRadius: '5px',
                      padding: '0.5rem',
                      overflow: 'auto',
                      fontSize: '16px',
                      fontFamily: 'monospace'
                    }}>
                    python add_arn.py
                    <button onClick={(e) => copyToClipboard('python add_arn.py', 'python', e)}
                      style={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        padding: '2px 8px',
                        fontSize: '12px'
                      }}
                      className="btn">
                      <ContentPasteIcon />
                    </button>
                  </div>
                </div>
                <div className="mb-4">
                  <h6 className="mb-2 font-weight-bold">Step 3: Re-check Permissions</h6>
                  <p>
                    Once the script has successfully run, click the button below to verify that your AWS account now has the required permissions.
                  </p>
                  {message && (
                    <p className="text-danger fw-bold mt-3">
                      {message}
                    </p>
                  )}
                  <div className="mt-4 text-center">
                    <button className="btn btn-primary"
                      onClick={handleRecheck}
                      disabled={loading}
                      style={{ padding: '10px 30px' }}>
                      {loading ? "Checking..." : "Re-check Permissions"}
                    </button>
                  </div>
                  <p className="text-center mt-3" style={{ fontSize: "0.9rem", color: "#555" }}>
                    If issues persist, please contact support.
                  </p>
                </div>
              </div>
            </div>
            {/* Right Column: Script Display */}
            <div className="col-md-12 col-lg-6">
              <div className="bg-white p-3 p-lg-4 desh-height">
                <h4 className="text-center text-black mb-3 font-weight-bold">
                  AWS IAM Role Creation Script
                </h4>
                <div className="position-relative"
                  style={{
                    backgroundColor: "#f8f9fa",
                    border: "1px solid #dee2e6",
                    borderRadius: "5px",
                    padding: "1rem"
                  }}>
                  <button onClick={(e) => copyToClipboard(script, 'python script', e)}
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      padding: '2px 8px',
                      fontSize: '12px'
                    }}
                    className="btn btn-outline-primary btn-sm">
                    <ContentPasteIcon style={{ fontSize: "14px" }} /> {copiedCommand === 'python script' ? 'Copied!' : 'Copy Script'}
                  </button>
                  <SyntaxHighlighter language="python" style={vs}
                    customStyle={{
                      borderRadius: '5px',
                      padding: '0rem',
                      margin: '0rem',
                      backgroundColor: '#f8f9fa',
                      overflow: 'auto',
                      fontSize: '0.9rem'
                    }}>
                    {script}
                  </SyntaxHighlighter>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
  );
};

export default NoAccess;
