import { createSlice } from '@reduxjs/toolkit';
import { logout } from '../globalActions';

export const authSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    token: null,
    isAuthenticated: false,
    processedStatus: true,
    permissionStatus: false,
  },
  reducers: {
    setCredentials: (state, action) => {
      if (action.payload.user !== undefined) state.user = action.payload.user;
      if (action.payload.token !== undefined) {
        state.token = action.payload.token;
        state.isAuthenticated = true;
      }
      if (action.payload.processedStatus !== undefined) {
        state.processedStatus = action.payload.processedStatus;
      }
      if (action.payload.permissionStatus !== undefined) {
        state.permissionStatus = action.payload.permissionStatus;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, (state) => ({
      user: null,
      token: null,
      isAuthenticated: false,
      processedStatus: false,
      permissionStatus: false,
    }));
  }
});

export const { setCredentials } = authSlice.actions;

export default authSlice.reducer;
