import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import LoadingScreen from 'src/components/dashboard/Loading';
import Processing from 'src/components/info/Processing';
import useAuth from 'src/hooks/useAuth';
import useEnsureData from 'src/hooks/useEnsureData';
import useCheckProcessedStatus from 'src/hooks/useCheckProcessedStatus';

const ProtectedRoute = ({ component: Component, needsData = false, skipPermissionCheck = false }) => {
  const { isAuthenticated, isLoading: isLoadingAuth } = useAuth();
  const { isLoading: isLoadingData, loadingMessage } = useEnsureData(isAuthenticated && needsData);
  const { processedStatus, permissionStatus } = useSelector(state => state.user);
  const { isProcessing } = useCheckProcessedStatus();

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }
  
  if (permissionStatus === false) {
    return <Navigate to="/no-access" replace />;
  }

  if (isLoadingAuth || isLoadingData) {
    return <LoadingScreen message={loadingMessage} />;
  }

  return (
    <>
      <Component />
      {(!skipPermissionCheck && !processedStatus && isProcessing) && <Processing />}
    </>
  );
};

export default ProtectedRoute;
