import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    CategoryScale
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { format, subDays, subMonths, subYears, isWithinInterval } from 'date-fns';
import { awsService } from 'src/services/awsService';
import annotationPlugin from 'chartjs-plugin-annotation';
import { XAxis } from 'recharts';


// Register necessary Chart.js components
ChartJS.register(
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    annotationPlugin,
    CategoryScale
);

export default function CPU_Memory({ awsmoGroupId, token, granularity, accountId, loadBalancer }) {
    const [costData, setCostData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [utilizationData, setUtilizationData] = useState([]);

    const timeRanges = useMemo(() => ({
        '7d': { days: 7 },
    }), []);

    const { startDate, endDate } = useMemo(() => {
        const end = new Date();
        const start = (() => {
            switch (granularity) {
                case '7d':
                    return subDays(end, timeRanges['7d'].days);
                case '1m':
                    return subMonths(end, timeRanges['1m'].months);
                case '1y':
                    return subYears(end, timeRanges['1y'].years);
                default:
                    return subDays(end, timeRanges['7d'].days);
            }
        })();
        return { startDate: start, endDate: end };
    }, [granularity, timeRanges]);

    const processCostData = useCallback((data) => {
        const filteredData = data.filter(item => item.awsmo_group === awsmoGroupId);
        const sortedData = filteredData.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
        const dateFilteredData = sortedData.filter(item =>
            isWithinInterval(new Date(item.timestamp), { start: startDate, end: endDate })
        );
        return dateFilteredData.map(item => ({
            x: new Date(item.timestamp),
            y: item.total_per_hour_cost,
            details: item.data
        }));
    }, [awsmoGroupId, startDate, endDate]);

    const fetchCostData = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const data = await awsService.fetchAwsCost(token);
            const processedData = processCostData(data);


            setCostData(processedData);
        } catch (error) {
            console.error('Error fetching cost data:', error);
            setError('Failed to load cost data.');
        } finally {
            setIsLoading(false);
        }
    }, [token, processCostData]);

    const cpu_timeRanges = useMemo(() => ({
        '7d': { days: 7, granularity: 120 },

    }), []);

    const { cpu_startDate, cpu_endDate } = useMemo(() => {
        const end = new Date();

        const start = (() => {
            switch (granularity) {
                case '7d': return subDays(end, timeRanges['7d'].days);
                case '1m': return subMonths(end, timeRanges['1m'].months);
                case '1y': return subYears(end, timeRanges['1y'].years);
                default: return subDays(end, timeRanges['7d'].days);
            }
        })();

        return { cpu_startDate: start, cpu_endDate: end };
    }, [granularity, timeRanges]);

    const processUtilizationData = useCallback((data) => {
        return data.map(item => ({
            date: new Date(item.timestamp),
            value: item.cpu_usage,
        })).sort((a, b) => a.date - b.date);
    }, []);

    const fetchUtilizationData = useCallback(async (instanceIds) => {
        setIsLoading(true);
        setError(null);
        try {
            const data = await awsService.fetchInstanceUtilization(
                accountId,
                instanceIds,
                token,
                cpu_startDate,
                cpu_endDate,
                cpu_timeRanges[granularity].granularity
            );
            const processedData = data.map((instanceData) => ({
                instanceId: instanceData.instance_id,
                values: processUtilizationData(instanceData.actual_consumption.consumption)
            }));
            setUtilizationData(processedData);
        } catch (error) {
            console.error('Error fetching utilization data:', error);
            setError('Failed to load utilization data.');
        } finally {
            setIsLoading(false);
        }
    }, [accountId, token, cpu_startDate, cpu_endDate, granularity, cpu_timeRanges, processUtilizationData]);


    useEffect(() => {
        if (awsmoGroupId && loadBalancer && Array.isArray(loadBalancer.Instances) && loadBalancer.Instances.length > 0) {
            const instanceIds = loadBalancer.Instances.map(instance => instance.InstanceId);
            fetchUtilizationData(instanceIds);
            fetchCostData();
        } else {
            setCostData([]);
            setUtilizationData([]);
            setIsLoading(false);
            if (loadBalancer) {
                console.warn('No InstanceIds available for the selected load balancer.');
            }
        }
    }, [awsmoGroupId, granularity, fetchCostData]);

    const [minDate, maxDate] = useMemo(() => {
        if (costData.length === 0) return [new Date(), new Date()];
        const dates = costData.map(d => d.x);
        return [new Date(Math.min(...dates)), new Date(Math.max(...dates))];
    }, [costData]);

    const getMaxValue = useCallback(() => {
        if (costData.length === 0) return 100;
        const maxCost = Math.max(...costData.map(d => d.y));
        return maxCost * 1.1;
    }, [costData]);

    const getMaxValue_cpu = () => {
        if (utilizationData.length === 0) return 100; // Default max
        return Math.max(...utilizationData.flatMap(d => d.values.map(v => v.value)), 100);
    };


    const chartData = useMemo(() => ({
        datasets: [
            {
                label: 'Total Cost per Hour (USD)',
                data: costData,
                borderColor: 'rgb(75, 192, 192)',
                // backgroundColor: 'rgba(75, 192, 192, 0.2)',
                tension: 0.4,
                fill: true,
                pointRadius: 3,
                pointHoverRadius: 6,
                pointBackgroundColor: 'rgb(75, 192, 192)',
                xAxisID: 'x',
                yAxisID: 'y',
            },
            ...utilizationData.map((instance, index) => ({
                label: `Instance ${instance.instanceId}`,
                data: instance.values.map(({ date, value }) => ({ x: date, y: value })),
                borderColor: `hsl(${index * 137.5 % 360}, 70%, 50%)`,
                backgroundColor: `hsla(${index * 137.5 % 360}, 70%, 50%, 0.1)`,
                tension: 0.4,
                pointRadius: 2,
                pointBackgroundColor: `hsl(${index * 137.5 % 360}, 70%, 50%)`,
                fill: true,
                xAxisID: 'x2',
                yAxisID: 'y2', // Ensure it's on the utilization Y-axis
            })),
        ]
    }), [utilizationData, costData]);

    const chartOptions = useMemo(() => ({
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            intersect: false,
            mode: 'index',
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: granularity === '7d' ? 'hour' : 'day',
                    displayFormats: {
                        hour: 'MMM dd, HH:mm',
                        day: 'MMM dd'
                    }
                },
                title: {
                    display: true,
                    text: 'Cost Date'
                },
                ticks: {
                    maxRotation: 0,
                    autoSkip: true,
                    maxTicksLimit: 7
                },
                min: minDate,
                max: maxDate,
                grid: { drawOnChartArea: false },
            },
            y: {
                title: {
                    display: true,
                    text: 'Cost per Hour (USD)'
                },
                ticks: {
                    callback: (value) => value
                },
                suggestedMin: 0,
                suggestedMax: getMaxValue(),
                grid: { drawOnChartArea: false },
            },
            x2: {
                type: 'time',
                time: {
                    unit: granularity === '7d' ? 'hour' : 'day',
                    displayFormats: {
                        hour: 'MMM dd, HH:mm',
                        day: 'MMM dd'
                    }
                },
                title: {
                    display: true,
                    text: 'Utilization Date'
                },
                ticks: {
                    maxRotation: 0,
                    autoSkip: true,
                    maxTicksLimit: 7
                },
                min: startDate,
                max: endDate
            },
            y2: {
                title: {
                    display: true,
                    text: 'Utilization (Units)'
                },
                ticks: {
                    callback: (value) => value
                },
                suggestedMin: 0,
                suggestedMax: getMaxValue_cpu(),
                position: "right"
            }
        },
    }), [granularity, getMaxValue, minDate, maxDate, costData, getMaxValue_cpu]);


    if (isLoading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 'calc(100vh - 300px)'
                }}
            >
                <CircularProgress />
                <Typography variant="body1" sx={{ ml: 2 }}>
                    Loading CPU data...
                </Typography>
            </Box>
        );
    }

    if (error) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 'calc(100vh - 300px)'
                }}
            >
                <Typography variant="body1" color="error">
                    {error}
                </Typography>
            </Box>
        );
    }

    if (!isLoading && costData.length === 0 && utilizationData.length === 0) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 'calc(100vh - 300px)'
                }}
            >
                <Typography variant="body1">
                    No data available for the selected load balancer.
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex', gap: 3, height: 'calc(100vh - 300px)' }}>
            <Box sx={{ flex: 1, bgcolor: 'white', borderRadius: 1, p: 2 }}>
                <Line data={chartData} options={chartOptions} />
            </Box>
        </Box>
    );
}

