import React, { useRef, useState } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import Sidebar from 'src/components/dashboard/Sidebar';
import Header from 'src/components/dashboard/Header';
import NoAccess from 'src/components/info/NoAccess';
import useAuth from 'src/hooks/useAuth';

const AWSNoAccess = () => {
  const [inactive, setInactive] = useState(false);
  const [isColActive, setColActive] = useState('d');
  const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);
  const inputRef = useRef(null);
  const { performLogout } = useAuth();

  const setColumnActive = (e) => {
    if (isMobile === true && e !== 'p') {
      inputRef.current.click();
    }
    setColActive(e);
  };

  return (
    <div 
      className="aws-no-access-container" 
      style={{ padding: '20px', backgroundColor: '#f9f9f9', minHeight: '100vh' }}
    >
      <header>
        <Sidebar 
          takeRef={inputRef} 
          parentCallBack={setColumnActive} 
          isColActive={isColActive} 
          onCollapse={(inactive) => setInactive(inactive)} 
        />
        <Header parentCallBack={setColumnActive} isColActive={isColActive} />
        <div style={{ position: 'absolute', top: '15px', right: '20px', zIndex: 9999 }}>
          <button 
            onClick={performLogout} 
            className="btn btn-primary" 
            style={{ 
              display: 'flex', 
              alignItems: 'center', 
              padding: '10px 15px', 
              borderRadius: '10px', 
              fontWeight: '500' 
            }}
          >
             <LogoutIcon style={{ marginRight: '8px' }} />
            Logout
          </button>
        </div>
      </header>
      <NoAccess inactive={inactive} />
    </div>
  );
};

export default AWSNoAccess;