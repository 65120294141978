import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCredentials } from 'src/redux/slices/authSlice';
import { orgService } from 'src/services/orgService';

function useCheckProcessedStatus() {
  const { token, processedStatus, permissionStatus } = useSelector(state => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    let interval;
    if (token) {
      const checkStatus = async () => {
        try {
          const orgData = await orgService.getOrganization(token);
          if (orgData && orgData.length > 0) {
            const awsAccountData = orgData[0]?.aws_accounts;
            const permissionStatus = awsAccountData[0]?.permission_status;
            const isProcessed = awsAccountData[0]?.stage === "STAGE_AG_CREATED";
            const newAccessStatus = permissionStatus && isProcessed;

            dispatch(setCredentials({ 
              user: orgData.user, 
              token, 
              processedStatus: newAccessStatus,
              permissionStatus
            }));
          }
        } catch (error) {
          console.error('Failed to fetch organization data:', error);
        }
      };

      checkStatus();
      interval = setInterval(checkStatus, 60000);
    }

    return () => interval && clearInterval(interval);
  }, [dispatch, token, processedStatus]);

  return { isProcessing: token && !processedStatus, permissionStatus };
}

export default useCheckProcessedStatus;
