// Utilization.js
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { format, subDays, subMonths, subYears } from 'date-fns';
import { awsService } from 'src/services/awsService';

ChartJS.register(TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function Utilization({ accountId, token, loadBalancer, granularity }) {
    const [utilizationData, setUtilizationData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null); // To handle and display errors

    // Define time ranges and their corresponding granularities
    const timeRanges = useMemo(() => ({
        '7d': { days: 7, granularity: 120 },
        '1m': { months: 1, granularity: 24 * 60 },
        '1y': { years: 1, granularity: 24 * 60 }
    }), []);

    // Calculate start and end dates based on granularity
    const { startDate, endDate } = useMemo(() => {
        const end = new Date();

        const start = (() => {
            switch (granularity) {
                case '7d': return subDays(end, timeRanges['7d'].days);
                case '1m': return subMonths(end, timeRanges['1m'].months);
                case '1y': return subYears(end, timeRanges['1y'].years);
                default: return subDays(end, timeRanges['7d'].days);
            }
        })();
        return { startDate: start, endDate: end };
    }, [granularity, timeRanges]);

    // Process utilization data
    const processUtilizationData = useCallback((data) => {
        return data.map(item => ({
            date: new Date(item.timestamp),
            value: item.cpu_usage,
        })).sort((a, b) => a.date - b.date);
    }, []);

    // Fetch utilization data
    const fetchUtilizationData = useCallback(async (instanceIds) => {
        setIsLoading(true);
        setError(null);
        try {
            const data = await awsService.fetchInstanceUtilization(
                accountId,
                instanceIds,
                token,
                startDate,
                endDate,
                timeRanges[granularity].granularity
            );
            const processedData = data.map((instanceData) => ({
                instanceId: instanceData.instance_id,
                values: processUtilizationData(instanceData.actual_consumption.consumption)
            }));
            let _processedData = []
            processedData.map((d)=>{
                if (d['values'].length != 0){
                    _processedData.push(d)
                }
            })
            setUtilizationData(_processedData);
        } catch (error) {
            console.error('Error fetching utilization data:', error);
            setError('Failed to load utilization data.');
        } finally {
            setIsLoading(false);
        }
    }, [accountId, token, startDate, endDate, granularity, timeRanges, processUtilizationData]);

    // Fetch data when component mounts, when loadBalancer or granularity changes
    useEffect(() => {
        if (loadBalancer && Array.isArray(loadBalancer.Instances) && loadBalancer.Instances.length > 0) {
            const instanceIds = loadBalancer.Instances.map(instance => instance.InstanceId);
            fetchUtilizationData(instanceIds);
        } else {
            setIsLoading(false);
            setUtilizationData([]);
            if (loadBalancer) {
                console.warn('No InstanceIds available for the selected load balancer.');
            }
        }
    }, [loadBalancer, fetchUtilizationData, granularity]);

    // Get max value for chart scaling
    const getMaxValue = () => {
        if (utilizationData.length === 0) return 100; // Default max
        return Math.max(...utilizationData.flatMap(d => d.values.map(v => v.value)), 100);
    };

    if (isLoading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 'calc(100vh - 300px)'
                }}
            >
                <CircularProgress />
                <Typography variant="body1" sx={{ ml: 2 }}>
                    Loading utilization data...
                </Typography>
            </Box>
        );
    }

    if (error) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 'calc(100vh - 300px)'
                }}
            >
                <Typography variant="body1" color="error">
                    {error}
                </Typography>
            </Box>
        );
    }

    if (!isLoading && utilizationData.length === 0) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 'calc(100vh - 300px)'
                }}
            >
                <Typography variant="body1">
                    No utilization data available for the selected load balancer.
                </Typography>
            </Box>
        );
    }

    // Chart configuration
    const chartData = {
        datasets: utilizationData.map((instance, index) => ({
            label: `Instance ${instance.instanceId}`,
            data: instance.values.map(({ date, value }) => ({ x: date, y: value })),
            borderColor: `hsl(${index * 137.5 % 360}, 70%, 50%)`,
            backgroundColor: `hsla(${index * 137.5 % 360}, 70%, 50%, 0.1)`,
            tension: 0.4,
            pointRadius: 2,
            pointBackgroundColor: `hsl(${index * 137.5 % 360}, 70%, 50%)`,
            fill: true
        }))
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: { display: true },
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    title: (context) => {
                        const date = new Date(context[0].parsed.x);
                        return format(date, granularity === '7d' ? 'MMM dd, yyyy HH:mm' : 'MMM dd, yyyy');
                    },
                    label: (context) => {
                        const value = context.parsed.y;
                        return `${context.dataset.label}: ${value.toFixed(2)} units`;
                    }
                }
            },
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: granularity === '7d' ? 'hour' : 'day',
                    displayFormats: {
                        hour: 'MMM dd, HH:mm',
                        day: 'MMM dd'
                    }
                },
                title: {
                    display: true,
                    text: 'Date'
                },
                ticks: {
                    maxRotation: 0,
                    autoSkip: true,
                    maxTicksLimit: 7
                },
                min: startDate,
                max: endDate
            },
            y: {
                title: {
                    display: true,
                    text: 'Utilization (Units)'
                },
                ticks: {
                    callback: (value) => value
                },
                suggestedMin: 0,
                suggestedMax: getMaxValue()
            }
        },
    };

    return (
        <Box sx={{ display: 'flex', gap: 3, height: 'calc(100vh - 300px)' }}>
            <Box sx={{ flex: 1, bgcolor: 'white', borderRadius: 1, p: 2 }}>
                <Line data={chartData} options={chartOptions} />
            </Box>
        </Box>
    );
}
