import React, { useState, useEffect } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/esm/styles/prism';
import useDocumentTitle from 'src/hooks/useDocumentTitle';
import useAwsAccount from 'src/hooks/useAwsAccount';
import { toast } from 'react-hot-toast';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import LoadingScreen from './Loading';

const AddAccount = () => {
    useDocumentTitle("Add Account - Awsmo");
    const { handleProceed, loading, loadingMessage, linkText, linkUrl } = useAwsAccount();
    const [clientArn, setClientArn] = useState('');
    const [copiedCommand, setCopiedCommand] = useState('');
    const [copyTimeout, setCopyTimeout] = useState(null);

    useEffect(() => {
        return () => {
            clearTimeout(copyTimeout);
        };
    }, [copyTimeout]);
    const onSubmit = () => {
        handleProceed(clientArn);
    };

    if (loading) {
        return <LoadingScreen message={loadingMessage} linkText={linkText} linkUrl={linkUrl} />;
    }


    const script = `import boto3
import json

# Create IAM client
iam = boto3.client('iam')

# Define the trust policy document
trust_policy_document = {
    "Version": "2012-10-17",
    "Statement": [
        {
            "Effect": "Allow",
            "Principal": {
                "AWS": "arn:aws:iam::145600289260:root"
            },
            "Action": "sts:AssumeRole"
        }
    ]
}

# Convert the trust policy document to a JSON string
trust_policy_document_str = json.dumps(trust_policy_document)

# Create the IAM role with the trust policy document
role_response = iam.create_role(
    RoleName='AWSMO_TrustedAdminAccessRole',
    AssumeRolePolicyDocument=trust_policy_document_str
)

# Attach the AWS managed AdministratorAccess policy to the role
iam.attach_role_policy(
    RoleName='AWSMO_TrustedAdminAccessRole',
    PolicyArn='arn:aws:iam::aws:policy/AdministratorAccess'
)

# Print the ARN of the IAM role
role_arn = role_response['Role']['Arn']
print("IAM role created with ARN:", role_arn)
`;

    const copyToClipboard = (text, command, event) => {
        event.stopPropagation();
        if (copiedCommand !== command) {
            clearTimeout(copyTimeout);
            navigator.clipboard.writeText(text).then(() => {
                setCopiedCommand(command);
                const toastId = toast.success('Copied to Clipboard');
                setCopyTimeout(setTimeout(() => {
                    toast.dismiss(toastId);
                }, 2000));
            }, (err) => {
                toast.error('Failed to copy script');
                console.error(err);
            });
        }
    };

    return (
        <>
            <div className='margin-top-dash font-open'>
                <div className='rightContainer'>
                    <div className='p-3 p-lg-4'>
                        <div className='d-flex justify-content-between align-items-center mb-3'>
                            <div className='text-black f18 font-open font-weight-bold'>
                                Connect your AWS Account
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12 col-lg-6'>
                                <div className="bg-white p-3 p-lg-4 desh-height">
                                    <h4 className="text-center text-black mb-3 font-weight-bold">
                                        Instructions to Connect Your AWS Account
                                    </h4>
                                    <div className="mb-4">
                                        Follow these steps carefully to establish a secure connection between your AWS account and our system:
                                    </div>

                                    <div className="mb-4">
                                        <h6 className="mb-2 font-weight-bold">Step 1: Obtain the Script</h6>
                                        <p>You have two options to get the script:</p>
                                        <strong>Option A:</strong> Click the "Copy Script" button and manually create a new file named <code>add_arn.py</code> in your AWS CloudShell, then paste the script
                                        <br />
                                        <strong>Option B:</strong> Directly download the script using the following command in your AWS CloudShell:
                                        <br />

                                        <div className="code-box align-center m-2 p-2 mb-2"
                                            style={{
                                                position: 'relative',
                                                width: '100%',
                                                backgroundColor: '#f8f9fa',
                                                border: '1px solid #dee2e6',
                                                borderRadius: '5px',
                                                padding: '0.5rem',
                                                overflow: 'auto',
                                                fontSize: '16px',
                                                fontFamily: 'monospace',

                                            }}>
                                            wget https://awsmo.ai/onboarding-script -O add_arn.py
                                            <button onClick={(e) => copyToClipboard('wget https://awsmo.ai/onboarding-script -O add_arn.py', 'wget', e)}
                                                style={{
                                                    position: 'absolute',
                                                    top: '5px',
                                                    right: '5px',
                                                    padding: '2px 8px',
                                                    fontSize: '12px',
                                                }} className="btn">
                                                <ContentPasteIcon />
                                            </button>

                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <h6 className="mb-2 font-weight-bold">Step 2: Execute the Script</h6>
                                        <p>
                                            After obtaining the script, execute it by typing the following command:
                                        </p>
                                        <div className="code-box align-center m-2 p-2 mb-2"
                                            style={{
                                                position: 'relative',
                                                width: '100%',
                                                backgroundColor: '#f8f9fa',
                                                border: '1px solid #dee2e6',
                                                borderRadius: '5px',
                                                padding: '0.5rem',
                                                overflow: 'auto',
                                                fontSize: '16px',
                                                fontFamily: 'monospace',

                                            }}>
                                            python add_arn.py
                                            <button onClick={(e) => copyToClipboard('python add_arn.py', 'python', e)}
                                                style={{
                                                    position: 'absolute',
                                                    top: '5px',
                                                    right: '5px',
                                                    padding: '2px 8px',
                                                    fontSize: '12px',
                                                }} className="btn">
                                                <ContentPasteIcon />
                                            </button>

                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <h6 className="mb-2 font-weight-bold">Step 3: Enter the Client ARN</h6>
                                        <p>
                                            Once the script has successfully run, it will output an IAM role ARN. Copy this ARN and input it in the field below to proceed with connecting your AWS account.
                                        </p>
                                        <input
                                            type="text"
                                            value={clientArn}
                                            onChange={(e) => setClientArn(e.target.value)}
                                            className="form-control"
                                            placeholder="Enter your client ARN here"
                                        />
                                        <button onClick={onSubmit} className="btn btn-primary mt-3">Proceed</button>
                                    </div>
                                </div>

                            </div>
                            <div className='col-md-12 col-lg-6'>
                                <div className="bg-white p-3 p-lg-4 desh-height">
                                    <h4 className="text-center text-black mb-3 font-weight-bold">
                                        AWS IAM Role Creation Script
                                    </h4>
                                    <div className="text-align-left mt-3"
                                        style={{
                                            position: 'relative',
                                            height: '100%',
                                            overflow: 'auto',
                                            borderRadius: '5px',
                                            backgroundColor: '#f8f9fa',
                                            border: '1px solid #dee2e6',
                                            padding: '1rem',
                                        }}>
                                        <button onClick={(e) => copyToClipboard(script, 'python script', e)}
                                            style={{
                                                position: 'absolute',
                                                top: '10px',
                                                right: '10px',
                                                padding: '2px 8px',
                                                fontSize: '12px',
                                            }} className="btn">
                                            <ContentPasteIcon /> Copy Script
                                        </button>

                                        <SyntaxHighlighter language="python" style={vs}
                                            customStyle={{
                                                borderRadius: '5px',
                                                padding: '0rem',
                                                margin: '0rem',
                                                backgroundColor: '#f8f9fa',
                                                overflow: 'auto'
                                            }}>
                                            {script}
                                        </SyntaxHighlighter>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default AddAccount;
